export default () => {
  //accordion bs
  $('.accordion').on('hidden.bs.collapse', function(event) {
    event.currentTarget.querySelector('i').classList = 'fa fa-plus';
  })
  $('.accordion').on('show.bs.collapse', function(event) {
    event.currentTarget.querySelector('i').classList = 'fa fa-minus';
  })
  //accordion world_map bs
  $('.world_map_area').on('hidden.bs.collapse', function(event) {
    event.currentTarget.querySelector('i').classList = 'fa fa-plus-circle';
  })
  $('.world_map_area').on('show.bs.collapse', function(event) {
    event.currentTarget.querySelector('i').classList = 'fa fa-minus-circle';
  })
}