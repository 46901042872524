'use strict'
<template>
  <div id="map">
    <br>
    <h3 v-if="loading" class="map_headline center">Bitte Warten...</h3>
    <h3 v-else class="map_headline center-text"><span>DIE YFU </span> GASTLÄNDER</h3>
    <br>
    <vue-world-map-country  ></vue-world-map-country>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading:true
    };
  },
  components: {
  "vue-world-map-country" : () => import('../vue_components/world_map_country')
  },
  mounted(){
    let self = this;
    self.loading = false;
  }
};
</script>
