export default () => {
  if ($(".brochure_order_form")[0]) {
    $(".brochure_order_form").on('submit', function (e) {

      // e.preventDefault();
      let ap = $('#brochure_order_brochure_ap').is(":checked"),
        ep = $('#brochure_order_brochure_ep').is(":checked"),
        firstname = $('#brochure_order_firstname')[0].value,
        lastname = $('#brochure_order_lastname')[0].value,
        street = $('#brochure_order_street')[0].value,
        zip = $('#brochure_order_zip')[0].value,
        city = $('#brochure_order_city')[0].value;

      if (!ap && !ep) {
        e.preventDefault();
        $('.error-brochure-selection').append('<div class="validation error">Es muss mindestens eine Broschüre ausgewählt werden.</div>');
        const errorContainer = $('.error-brochure-selection');
        $("html, body").animate(
          {
            scrollTop: $(".error-brochure-selection")
              .parent()
              .offset().top - 160,
          },
          200
        );
        return;
      }

      // google analytics 4 brochure order tracking
      if (typeof gtag === "function"
          && firstname !== ''
          && lastname !== ''
          && street !== ''
          && zip !== ''
          && city !== '') {
        //track success for ga
        if (ap == true && ep == false) {
          gtag ("event", "Broschüre AP", {
            content_type: "brochure_order_ap",
          });
        }
        else if (ep == true && ap == false) {
          gtag("event", "Broschüre EP", {
            content_type: "brochure_order_ep"
          });
        }
        else if (ap == true && ep == true) {
          gtag("event", "Broschüre AP EP", {
            content_type: "brochure_order_ap_ep"
          });
        }
      }
      // $(this).unbind('submit').submit();
    });
  }
};