export default (target) => {
  target.slick({
    arrows: true,
    dots: false,
    numbers: false,
    speed: 500,
    zIndex: 200,
    lazyLoad: 'ondemand',
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    // adaptiveHeight: true
    prevArrow:"<i class='a-left control-c prev slick-prev fas fa-chevron-circle-left'/>",
    nextArrow:"<i class='a-left control-c prev slick-next fas fa-chevron-circle-right'/>"
  });
};