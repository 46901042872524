<template>
  <div class="student_profile">
    <div class="center-text spinner" v-show="showSpinner">
      <i class="fa fa-circle-notch fa-spin"></i>
    </div>
    <!-- no student -->
    <span v-if="students_count == '0'">
      <article v-if="no_students_hint.length > 0" v-html="no_students_hint"></article>
    </span>
    <!-- only one student -->
    <span v-else-if="students_count == '2'" class="student_profile--placeholder">
      <div class="studentpicture pointer" v-on:click="sendToStudentGallery()">
        <img v-bind:src="students[0].portrait_picture_url" loading="lazy" v-bind:alt="'Placeholder'">
        <div class="student_profile-silde-text absolute">
          <section class="size-wrap">
            <h5 class="ellipsis-text pull-left">
              Ihr zukünftiges Gastkind<br />aus einem Land Ihrer Wahl
            </h5>
            <div class="clearfix"></div>
          </section>
        </div>
      </div>
    </span>
    <!-- else more than one student   -->
    <span v-else>
      <div class="carousel-wrapper relative center">
        <carousel
          navigationNextLabel="<i class='fas fa-chevron-circle-right'></i>"
          navigationPrevLabel="<i class='fas fa-chevron-circle-left'></i>"
          v-show="!showSpinner"
          v-if="showSlider"
          :perPage="howManyStudentToShow"
          :paginationEnabled="false"
          :navigationEnabled="true"
          :perPageCustom="[[320, 1],  [1200, (parseInt(howManyStudentToShow))]]"
        >
          <slide
            class="link_to_gallery"
            v-on:slideClick="sendToStudentGallery()"
            v-for="student in students"
            :key="student.id"
          >
            <div class="studentpicture">
              <img v-bind:src="student.portrait_picture_url" loading="lazy" v-bind:alt="'Austauschschüler ' + student.firstname + ', ' + student.age + ' Jahre aus ' + student.country.case_name">
              <div class="student_profile-silde-text absolute">
                <section class="size-wrap">
                  <h5 class="pull-left">
                    {{ student.firstname }}, {{ student.age }} Jahre<br />aus
                    {{ student.country.case_name }}
                  </h5>
                  <div class="clearfix"></div>
                </section>
              </div>
            </div>
          </slide>
        </carousel>
      </div>
    </span>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
export default {
  data: function () {
    return {
      students: [],
      students_count: "",
      no_students_hint: "",
      showSpinner: true,
      howManyStudentToShow: 0,
      howManyStudentToShowDataset: 0,
      studentLimt: 0,
      navigationNextLabel: ">",
      navigationPrevLabel: "<",
      showSlider: false,
      link: "",
    };
  },
  methods: {
    sendToStudentGallery: function (event) {
      if (this.link != "") window.location.href = this.link;
    },
    getWindowWidth(a) {
      let self = this;
      if (
        window.innerWidth >= 769 &&
        window.innerWidth <= 1199 &&
        self.howManyStudentToShowDataset > 1
      ) {
        self.howManyStudentToShow = 2;
      } else if (window.innerWidth <= 768) {
        self.howManyStudentToShow = 1;
      } else {
        self.howManyStudentToShow = self.howManyStudentToShowDataset;
      }
    },
  },
  components: {
    Carousel,
    Slide,
  },
  mounted() {
    this.studentLimt = parseInt(this.$el.parentElement.dataset.limit);
    this.howManyStudentToShowDataset = parseInt(
      this.$el.parentElement.dataset.howManyStudentToShow
    );
    this.link = this.$el.parentElement.dataset.link;
    this.no_students_hint = this.$el.parentElement.dataset.noStudentsHint;
    this.$http
      .get("/api/student_profiles/?skip=0&limit=" + this.studentLimt)
      .then((resp) => {
        this.students = resp.data.students;
        this.students_count = resp.data.students_count.count;
        this.showSpinner = false;
        this.showSlider = true;
        this.getWindowWidth();
      });
    this.$nextTick(function () {
      window.addEventListener("resize", this.getWindowWidth);
    });
  },
}
</script>
