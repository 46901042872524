export default () => {
  function navigatonMenu(trigger, target, _event) {
    function navigatonToggle(event) {
      var trigger = $(this);
      $(".nav-tilelayout ul.navigation")
        .not("ul.navigation.level_1")
        .addClass("hidden");
      $(".nav-tilelayout ul.navigation button")
        .removeClass("minus")
        .addClass("plus");
      target.toggleClass("slide-in show hidden slide-out");
      $("body").toggleClass("no-scroll");
      $(".navigation-wrap").toggleClass("fixed");
      $(".social-links-wrap")
        .toggleClass("fixed")
        .next()
        .toggleClass("pull-down");
      target.find("input").focus();
    }
    trigger.on(_event, function(event) {
      navigatonToggle(event);
    });
  };

  function toggle(trigger, target) {
    trigger.on("click", function() {
      var _this = $(this);
      _this.next().toggleClass("hidden");
      target.not(_this.next()).addClass("hidden");
      _this.addClass("minus").removeClass("plus");
      trigger
        .not(_this)
        .removeClass("minus")
        .addClass("plus");
      if (_this.next().hasClass("hidden"))
        _this.addClass("plus").removeClass("minus");
      else _this.addClass("minus").removeClass("plus");
    });
  }

  //inti func
  if ($(".navbar-toggle-btn")[0]) {
    $("#nav-icon").click(function() {
      $(this).toggleClass("open");
    });
    $("#nav-menu-link").click(function() {
      $("#nav-icon").toggleClass("open");
    });
    toggle($(".toggle-btn.level_3"), $("ul.navigation.level_3"));
    toggle($(".toggle-btn.level_2"), $("ul.navigation.level_2"));
    navigatonMenu(
      $(".navbar-toggle-btn"),
      $(".navbar-toggle-left"),
      "click"
    );
  };
};
