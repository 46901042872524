export default () => {
  function BackToTop(target) {
    $(window).on("scroll.target", function() {
      if ($(window).scrollTop() <= $("header").height() + 200) {
        target.addClass("hidden");
      } else {
        target.removeClass("hidden");
      }
    });
    target.click(function() {
      $("body,html").animate({
          scrollTop: 0
        },
        500
      );
    });
  };
  BackToTop($(".backToTop"));
};
