export default () => {
    const consentToMarketingCookies = function(_event) {
        if (window.CookieConsent) {
            const consent = window.CookieConsent.consent;

            window.CookieConsent.submitCustomConsent(
                consent.preferences,
                consent.statistics,
                true  // Change marketing consent to true
            );
        }
    }

    window.consentToMarketingCookies = consentToMarketingCookies;
}
