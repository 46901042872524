export default (target) => {
  let selectorList = document.querySelectorAll(target);
  selectorList.forEach((element) => {
    element.addEventListener('click', (e) => {
      anchorFixedHeader(e.target)
    });
  });
  let anchorFixedHeader = (eventTaget) => {
    if (eventTaget.getAttribute('href')) {
      let fixedHeaderHeight = document.querySelector('header').offsetHeight;
      // sticky is disabled on 1024 max
      let fixedSubnav;
      if (window.innerWidth > 1024) {
        fixedSubnav = document.querySelector('.static_subnav') ? document.querySelector('.static_subnav').offsetHeight : 0;
      } else fixedSubnav = 0;

      //jquery version
      let target = $(eventTaget.getAttribute('href'));
      $('html, body').animate({
        scrollTop: target.offset().top - (fixedHeaderHeight + fixedSubnav)
      }, 300);

      //vanilla version needs animation target.offset().top needs to be rewritten in vanliia js
      // let target = document.querySelector(eventTaget.getAttribute('href'));
      // target.scrollBy({
      //   top: target.offsetTop - (fixedHeaderHeight + fixedSubnav),
      //   behavior: 'smooth'
      // });
    }
  };
};