"use strict";
export default () => {
  function createNavigationScroll() {
    ////////new version without settimeout///////
    ///////work with Traversing when multiple Elements////
    let scroll = false,
      $staticSubnav = $('.static_subnav'),
      $menu = $('.menu'),
      $rightPaddle = $('.right-paddle'),
      $leftPaddle = $('.left-paddle');

    // detected el scrollstate
    function scrollStateDetected(scrollEl, offsetEl, right, left) {
      if (scrollEl.scrollLeft() == 0) {
        left.addClass('hidden');
        right.removeClass('hidden');
      } else if (scrollEl.scrollLeft() < (scrollEl.get(0).scrollWidth - offsetEl.get(0).offsetWidth)) {
        left.removeClass('hidden');
        right.removeClass('hidden');
      } else {
        right.addClass('hidden');
        left.removeClass('hidden');
      }
    };

    // detected if el scrollable
    function scrollbarDetected(scrollEl, right) {
      if (scrollEl.get(0).scrollWidth > scrollEl.get(0).offsetWidth) {
        scroll = true;
        right.removeClass('hidden');
      } else {
        right.addClass('hidden');
      }
    };

    // detected if el scrollable on load
    scrollbarDetected($menu, $rightPaddle);

    // init on resize
    // $(window).on('resize', function() {
    //   scrollbarDetected($menu, $rightPaddle);
    // });

    if (scroll) {
      // scrollbar
      $menu.on('scroll', function() {
        scrollStateDetected($menu, $staticSubnav, $rightPaddle, $leftPaddle);
      });
      // scroll to right
      $rightPaddle.on('click', function() {
        $menu.animate({
          scrollLeft: $staticSubnav.get(0).offsetWidth
        }, 300);
        scrollStateDetected($menu, $staticSubnav, $rightPaddle, $leftPaddle);
      });
      // scroll to left
      $leftPaddle.on('click', function() {
        $menu.animate({
          scrollLeft: '0'
        }, 300);
        scrollStateDetected($menu, $staticSubnav, $rightPaddle, $leftPaddle);
      });
    }
  }
  ///if element is in Dom
  if ($(".static_subnav").get(0)) {
    //on load
    createNavigationScroll();
    //if resize fire again
    $(window).on('resize', function() {
      createNavigationScroll();
    });
  };
};