"use strict";
export default () => {
  //track height from bootstrap col grid
  function heightEqualise(
    widthsetter,
    widthgetter,
    _event
  ) {
    function Equalise() {
      widthgetter.each(function () {
        var target = $(this);
        var cw = widthsetter[0].getBoundingClientRect().width;
        widthgetter.css({
          height: cw + "px"
        });
        if (
          $(this)
            .siblings()
            .find(".text").length > 0
        )
          $(this)
            .siblings()
            .find(".text");
      });
    }
    Equalise();
    //events
    $(window).on(_event, function () {
      Equalise();
    });
  }
  // if ($(".tile-quarter")[0]) {
  //   heightEqualise(
  //     $(".tile-quarter"),
  //     $(".two-quarter"),
  //     "resize"
  //   );
  // };
};
