export default () => {
  if ($(".membership_application_form")[0]) {

    //handling part
    let $parentgroupmemberinput = $(".parent-group-member input"),
      $parentgroupmemberinputtoggle = $(".parent-group-member-toggle input"),
      $childgroupmemberinput = $(".child-group-member input");

    //check debit authorization exists on load
    checkDebitAuthorizationExsits();
    //disable and empty if not checked
    $parentgroupmemberinput.change(function (e) {
      let $togglechildgroup = $(this)
        .closest(".check-parent-group-member")
        .next(".toggle-childgroup");
      if (e.target.checked) {
        $('#membership_application_fee_amount').prop("disabled", true);
        $togglechildgroup.removeClass("disabled");
        $childgroupmemberinput.prop("disabled", false);
        checkFeeAmountCustom();
      } else {
        $('#membership_application_fee_amount').prop("disabled", false);
        $togglechildgroup.addClass("disabled");
        $childgroupmemberinput.prop("disabled", true);
      }
    });

    $childgroupmemberinput.change(function () {
      checkFeeAmountCustom();
    });

    $parentgroupmemberinputtoggle.change(function () {
      $parentgroupmemberinputtoggle
        .not(this)
        .prop("checked", false);
      if ($(this)[0].id === 'membership_application_bank_transfer' && $(this).is(':checked')) {
        checkDebitAuthorizationExsits();
        $('.toggle-childgroup#bank_account_info').hide();
        $('.toggle-childgroup#bank_account_info input').prop("disabled", true);
        $('.toggle-childgroup#bank_account_info input').addClass('disabled');
      } else {
        $('.toggle-childgroup#bank_account_info').show();
        $('.toggle-childgroup#bank_account_info input').prop('disabled', false);
        $('.toggle-childgroup#bank_account_info input').removeClass('disabled');
      }
    });

    // datepickers
    $('.bs-datepicker input').each(function () {
      let _this = $(this),
        date = new Date(),
        d = 10,
        m = date.getMonth() + 1,
        y = date.getFullYear(),
        fy = y + 2,
        fd = d < 10 ? '0' + d : d,
        fm = m < 10 ? '0' + m : m,
        newDate = `${fd}.${fm}.${fy}`;

      if (_this[0].id === 'membership_application_person_attributes_date_of_birth') {
        _this.datepicker({
          language: "de",
          clearBtn: true,
          autoclose: true,
          startDate: '01.01.1900',
          endDate: '-15y'
        });
      } else {
        _this.datepicker({
          language: "de",
          clearBtn: true,
          autoclose: true,
          format: "yyyy",
          viewMode: "years",
          minViewMode: "years",
          startDate: '1957',
          endDate: newDate
        });
      }
    });

    //js validation part
    $(".membership_application_form")
      .on("ajax:beforeSend", function (e) {
        let $this = $(this),
          scrollErr = false;
        $this.find(".validation").remove();

        $(".membership_application_form  .js-validation").each(function (i) {
          let $this = $(this);
          if ($this.val() === "") {
            e.preventDefault();
            $this.parent().append(
              '<div class="validation error">muss ausgefüllt werden</div>'
            );
            scrollErr = true;
          }
        });

        if (!$('#membership_application_applied_for_membership').is(":checked") && (!$('.update_membership_application_form')[0])) {
          e.preventDefault();
          $('#membership_application_applied_for_membership').parent().parent().append(
            '<div class="validation error">muss zugestimmt werden</div>'
          );
          scrollErr = true;
        }

        if ($("#membership_application_person_attributes_addresses_attributes_email").val() != $("#membership_application_person_attributes_addresses_attributes_email_confirmation").val()) {
          e.preventDefault();
          $(".membership_application_person_addresses_email_confirmation").append(
            '<div class="validation error">Email muss übereinstimmen</div>'
          );
          scrollErr = true;
        }

        if (scrollErr) scrollValidationErrors();
      });
    $(".membership_application_form")
      .on("ajax:success", function (e) {
        let errors = e.detail[0].errors,
          $this = $(this);
        //api validation errors
        if (!$.isEmptyObject(errors)) {
          if (errors.fee_amount) {
            $(".membership_application_fee_amount_custom").append(
              '<div class="validation error">' +
              errors.fee_amount +
              "</div>"
            );
          }

          if (errors.base) {
            $this.prepend(
              '<div class="validation error">' +
              errors.base +
              "</div>"
            );
          }
          if (errors.person) {
            renderValidationErrors(
              errors.person,
              ".membership_application_person_"
            );
            if (errors.person.bank_accounts) {
              renderValidationErrors(
                errors.person.bank_accounts,
                ".membership_application_person_bank_accounts_"
              );
            }
            if (errors.person.addresses) {
              renderValidationErrors(
                errors.person.addresses,
                ".membership_application_person_addresses_"
              );
            }
          }
          scrollValidationErrors();
        } else {
          $('#membership_submit').prop('disabled', true).css('background', 'lightgray');
          window.location.href = $this.data("success-page-url");
        }
      }).on("ajax:error", function (e) {
        let $this = $(this);
        switch (e.detail[2].status) {
          //api errors 
          case 500:
            $this.prepend(
              '<div class="validation error">' + e.detail[1] + '</div>'
            );
            scrollValidationErrors();
            break;
        }
      });

    $('input#membership_application_person_attributes_first_name, input#membership_application_person_attributes_last_name').on('change', function () {
      if ($('#membership_application_person_attributes_first_name').val() != '' && $('#membership_application_person_attributes_last_name').val() != '') {
        let name = `${$('input#membership_application_person_attributes_first_name').val()} ${$('input#membership_application_person_attributes_last_name').val()}`;
        $('input#membership_application_person_attributes_bank_accounts_attributes_account_holder_name').val(name);
      }else{
        $('input#membership_application_person_attributes_bank_accounts_attributes_account_holder_name').val('');
      }
    });
    //Events
    $('.debit_authorization').on('change', function () {
      checkDebitAuthorizationExsits();
    });
    $('.debit_authorization_exists').on('change', function () {
      checkDebitAuthorizationExsits();
    });

    //functions
    function checkDebitAuthorizationExsits(){
      if ($('.debit_authorization').is(':checked')) {
        $('.debit_authorization_exists_fieldset').removeClass('hidden');
      }
      else {
        $('.debit_authorization_exists').prop('checked', false)
        $('.debit_authorization_exists_fieldset').addClass('hidden');
      }
      if ($('.debit_authorization_exists').is(':checked')) {
        $('#bank_account_info').attr('disabled', true).addClass('hidden');
      }
      else{ 
        $('#bank_account_info').removeAttr('disabled').removeClass('hidden');
      }
    }
    function scrollValidationErrors() {
      $("html, body").animate(
        {
          scrollTop: $(".validation")
            .first()
            .parent()
            .offset().top - 160,
        },
        200
      );
    }
    function renderValidationErrors(err, target) {
      $.each(err, function (key, value) {
        $(target + key).append(
          '<div class="validation error">' + value + "</div>"
        );
      });
    }
    function checkFeeAmountCustom() {
      if ($('#membership_application_fee_amount_0').is(':checked')) {
        $('#membership_application_fee_amount_custom').prop("disabled", false);
      } else {
        $('#membership_application_fee_amount_custom').val('');
        $('#membership_application_fee_amount_custom').prop("disabled", true);
      }
    }
  }
};
