'use strict'
<template>
<span>
  <br>
  <br>
  <div class="row">
    <div class="col-lg-8 col-xl-8 order-2 order-lg-12">
      <studentsloop :counter="counter" :Params="Params" :load-limit="loadLimit" :load-more="loadMore" :starlets="starlets" :students="students" :update="update" :studentscount="studentscount"></studentsloop>
    </div>
    <div class="col-lg-4 col-xl-4 order-1 order-lg-12">
      <div class="bg-3 aside-wrap">
        <div class="text">
          <p>
            Sie können aktuell aus
            <b> {{studentscount.count}}</b>
            Schüler*innen aus bis zu 50 Ländern wählen.
          </p>
        </div>
        <h4>
          Verfeinern Sie Ihre Auswahl durch Filtern:
        </h4>
        <filters :filters="filters" :load-limit="loadLimit" :students="students" :counter="counter" :Params="Params" :hobbies="hobbies" :update="update" :studentscount="studentscount"></filters>
        <div>
          <br>
          <p v-if="studentscount.count == studentscount.count_filter">
            Es werden
            <b> {{students.length}}</b>
            von
            <b> {{studentscount.count_filter}}</b>
            angezeigt.
          </p>
          <p v-else>
            Es werden
            <b> {{students.length}}</b>
            von
            <b> {{studentscount.count_filter}}</b>
            Schüler<span v-if="students.length > 1">n</span> angezeigt, zu denen Ihre Suche passt.
          </p>
        </div>
        <br>
        <br>
      </div>
      <div class="bg-5 aside-wrap">
        <div class="text">
           Ihre bisherigen Favoriten:
          <starlets :starlets="starlets" :students="students"></starlets>
        </div>
        <br>
        <div class="text">
           Glauben Sie, es könnte passen?
          <br>
          <h4> Dann melden Sie sich jetzt für Ihre Favoritenauswahl!</h4>
          <starletapplicationform :sended="sended"></starletapplicationform>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  </div>
</span>
</template>

<script>
export default {
  data() {
    return {
      sended: false,
      hobbies: [],
      update: {
        spinner: true,
        loadMore: false,
      },
      counter: 0,
      loadLimit: 0,
      loadMore: false,
      skip: 0,
      Params: "",
      showStarlets: true,
      starlets: [],
      students: [],
      created: false,
      studentscount: [],
      filters: {
        genders: [],
        hosting_times: [],
        ages: []
      },
      localStorageIds: []
    }
  },
  components: {
    'studentsloop': () => import('../vue_components/studentsloop'),
    'filters': () => import('../vue_components/filters'),
    'starlets': () => import('../vue_components/starlets'),
    'starletapplicationform': () => import('../vue_components/starletapplicationform')
  },
  methods: {
    DataLength: function(counter) {
      var self = this;
      self.counter += 1;
      if (window.innerWidth > 600) {
        self.loadLimit = 40;
        self.skip = counter * self.loadLimit;
      } else {
        self.loadLimit = 20;
        self.skip = counter * self.loadLimit;
      }
    },
    postData: function(url, obj) {
      var self = this;
      self.update.spinner = true;
      this.$http.post('/api/' + url + '?' + self.Params).then(resp => {
        self.update.spinner = false;
        self.sended = true;
      });
      self.update.spinner = false;
    },
    getData: function(counter, url, obj) {
      //count func
      var self = this;
      self.DataLength(counter);
      // sent get request
      this.$http.get('/api/' + url + '/?skip=' + self.skip + '&limit=' + self.loadLimit + self.Params).then(resp => {
        // push responddata into data obj
        self.studentscount = [];
        self.studentscount = resp.data.students_count;
        //check for loadlimit
        self.checkLength(resp.data.students);
        //concat
        self.students = self.students.concat(resp.data.students);
        self.progessivImgLoad();
        this.update.spinner = false;
        this.update.loadMore = false;
        //add fav data prop to students
        self.students.forEach(function(student) {
          student.fav = false;
        });
      });
    },
    progessivImgLoad: function() {
      //progessiv img load
      var self = this;
      self.students.forEach(function(item) {
        item.blur = "blur";
        item.portraitLoaded = false;
        var imgLarge = new Image();
        imgLarge.src = item.portrait_picture_url;
        imgLarge.onload = function() {
          item.svg_image_url = item.portrait_picture_url;
          item.blur = "transitionandblur";
          item.portraitLoaded = true;
        };
      });
    },
    getDatasAsUriParameters: function(data) {
      var self = this;
      //return url no nested objects!
      //returns and map parentData form Array
      var ParamsAsset = Object.keys(data).map(function(item) {
        //checks if Array
        if (Array.isArray(data[item])) {
          var arrayItem = encodeURIComponent(item);
          //returns and map childData form Array
          return data[item].map(function(childData) {
            return arrayItem + '[]=' + encodeURIComponent(childData);
          }).join('&');
        }
        //checks if String or normal obj
        else {
          return encodeURIComponent(item) + '=' + encodeURIComponent(data[item]);
        }
      }).filter(Boolean);
      self.Params = 0 < ParamsAsset.length ? '&' + ParamsAsset.join('&') : "";

    },
    checkLength: function(data) {
      if (data.length < self.loadLimit) {
        self.loadMore = false;
      } else {
        self.loadMore = true;
      };
    }
  },
  updated: function() {
    var self = this;
    //load favs
    for (var j = 0; j < self.students.length; j++) {
      for (var i = 0; i < self.starlets.length; i++) {
        if (self.starlets[i].id === self.students[j].id) {
          self.students[j].fav = true;
        }
      }
    };
  },
  mounted: function() {
    var self = this;
    self.update.spinner == true;
    //rest datacount
    self.DataLength(0);
    // init localStorage
    if (localStorage.getItem("storedData")) {
      self.starlets = JSON.parse(localStorage.getItem("storedData"));
      self.starlets.forEach(function(starlet) {
        self.localStorageIds.push(starlet.id);
      });
      this.$http.get('/api/local_storage/?id[]=' + self.localStorageIds.join('&id[]=')).then(resp => {
        for (var i = 0; i < resp.data.length; i++) {
          var expired = true;
          for (var j = 0; j < self.starlets.length; j++) {
            if (self.starlets[j].id == resp.data[i].id) {
              self.starlets[j].portrait_picture_url = resp.data[i].portrait_picture_url;
              self.starlets[j].portrait_picture_url;
              var expired = false;
              break;
            }
          };
          if (expired) {
            self.starlets.splice(self.starlets.indexOf(resp.data[i]), 1);
          }
        };
      }, (resp) => {
        console.log('error on validation');
      });
    };
    //get init data
    this.$http.get('/api/student_profiles/?skip=' + self.skip + '&limit=' + self.loadLimit).then(resp => {
      // set StudentsCount
      self.studentscount = resp.data.students_count;
      // set Students
      self.students = resp.data.students;
      //hide until rendered
      document.getElementById("student_profiles").classList.remove("hidden")
      //checkLength for loadmore btn
      self.checkLength(resp.data.students);
      self.progessivImgLoad();
      //add fav data prop to students
      self.students.forEach(function(student) {
        student.fav = false;
      });
      self.update.spinner = false;
    });
    //get init filters
    self.$http.get('/api/student_filters').then(resp => {
      self.filters = resp.data;
      self.hobbies = self.filters.hobbies;
    });
    self.created = true;
  },
  beforeUpdate: function() {
    var self = this;
    // checkLength for loadmore btn
    self.checkLength(self.students);
  },
  watch: {
    starlets: function() {
      let self = this;
      if (window.localStorage) {
        localStorage.setItem("storedData", JSON.stringify(self.starlets));
        if (self.starlets.length == 0) {
          localStorage.removeItem("storedData", JSON.stringify(self.starlets));
        }
      };
      for (var j = 0; j < self.students.length; j++) {
        var found = false;
        for (var i = 0; i < self.starlets.length; i++) {
          if (self.starlets[i].id === self.students[j].id) {
            found = true;
          }
        }
        if (found) {
          self.students[j].fav = true;
        } else {
          self.students[j].fav = false;
        }
      };
    },
    Params: function() {
      var self = this;
      if (self.Params === "") {
        //get init data
        self.update.spinner = true;
        this.$http.get('/api/student_profiles/?skip=' + self.skip + '&limit=' + self.loadLimit).then(resp => {
          self.update.spinner = true;
          self.studentscount = [];
          self.studentscount = resp.data.students_count;
          self.students = resp.data.students;
          self.checkLength(self.students);
          self.progessivImgLoad();
          //add fav data prop to students
          self.students.forEach(function(student) {
            student.fav = false;
          });
          self.update.spinner = false;
        });
        //get init filters
        self.$http.get('/api/student_filters').then(resp => {
          self.update.spinner = true;
          self.filters = resp.data;
          self.hobbies = self.filters.hobbies;
          self.update.spinner = false;
        })
      };
    }
  }
};
</script>
