"use strict"
/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

//Jquery
// import 'jquery-validation'

//polyfill
import 'intersection-observer';
//require('default-passive-events');
//frameworks
// wait until jquery is gone...
// import 'bootstrap.native/dist/bootstrap-native-v4';
// import 'bootstrap';

//Bootstrap  --Jquery  
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/modal';
import '../vendor/bootstrap';
//bs datepicker 
import 'bootstrap-datepicker';
import 'bootstrap-datepicker/js/locales/bootstrap-datepicker.de.js';
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.standalone.min.css';

// import '@fortawesome/fontawesome-free/js/all';
import "@fortawesome/fontawesome-free/css/all"

//slick slider --Jquery
import 'slick-carousel';
import 'slick-carousel/slick/slick.css';

// Vue 
import Vue from 'vue';
import VueResource from 'vue-resource';
import VeeValidate from 'vee-validate';

// Vue Apps
import StudentProfiles from '../vue_apps/student_profiles';
import MiniStudentProfiles from '../vue_apps/student_profile';
import WorldMap from '../vue_apps/world_map';
import InterestForm from '../vue_apps/interest_form';
import HostfamilyRegistrationsForm from '../vue_apps/hostfamily_registration_form';

// Costum Stylesheets
import MainStyles from "../stylesheets/style";

// Costum Scripts
import Main from '../scripts/custom/main';

// init 
document.addEventListener('DOMContentLoaded', () => {
  //init new Vue Instance for each
  const vueSelectorArray = Array.from(document.querySelectorAll('.vue_component')).map(item => item.id);
  vueSelectorArray.forEach(function(vueInstance) {
    let component;
    let vueInstanceData;
    //check DOM
    if (vueInstance.includes("worldmap_container")) {
      Vue.use(VueResource);
      // Vue.use(VueLazyload)
      component = WorldMap;
    }
    if (vueInstance.includes("student_profile_container")) {
      Vue.use(VueResource);
      component = MiniStudentProfiles;
    }
    if (vueInstance.includes("student_profiles_container")) {
      Vue.use(VueResource);
      Vue.use(VeeValidate);
      component = StudentProfiles;
    };
    if (vueInstance.includes("interest_form_container")) {
      Vue.use(VueResource);
      Vue.use(VeeValidate);
      component = InterestForm;
    };
    if (vueInstance.includes("hostfamily_registration_form_container")) {
      Vue.use(VueResource);
      Vue.use(VeeValidate);
      component = HostfamilyRegistrationsForm;
    };
    // create new Vue instance form vueInstance
    const vm = new Vue({
      el: `#${vueInstance}`,
      render: h => h(component)
    });
  });
  Main();
});