export default () => {
  $('#membership_application_person_attributes_bank_accounts_attributes_debit_authorization').on('change', function(){
    if($('#membership_application_person_attributes_bank_accounts_attributes_debit_authorization').is(":checked")) {
      $('.manual-account-info-input').show();
    } else {
      $('.manual-account-info-input').hide();
    }
  });

  $('#sustaining-member-bank-transfer').on('change', function(){
    if($('#sustaining-member-bank-transfer').is(":checked")) {
      $('#membership_application_person_attributes_bank_accounts_attributes_debit_authorization').prop('checked', false);
      $('.manual-account-info-input').hide();
    } else {
      $('#membership_application_person_attributes_bank_accounts_attributes_debit_authorization').prop('checked', true);
      $('.manual-account-info-input').show();
    }
  });
}
