export default () => {
  $('.moretxt').on('click', (event) => {
    let prevtxt = $(event.target).prev().prev();
    let fulltxt = $(event.target).prev();
    prevtxt.toggleClass('hidden');
    if (!prevtxt.hasClass("hidden")) {
      fulltxt.addClass('hidden');
      event.target.innerText = event.target.dataset.toogleOut;
    } else {
      fulltxt.removeClass('hidden');
      event.target.innerText = event.target.dataset.toggleIn;
    }
  });
}