export default () => {
  if (typeof $ !== 'undefined') {
    const download_path = /download/;
    let baseHref = '';
    if ($('base').attr('href') !== undefined) {
      baseHref = $('base').attr('href');
    }
    $('a').each(function () {
      const href = $(this).attr('href');
      if (href && href.match(/^https?\:/i) && !href.match(document.domain)) {
        $(this).click(function () {
          const extLink = href.replace(/^https?\:\/\//i, '');
          // if (typeof window.ga !== 'undefined') {
          //   window.ga('send', 'event', 'External', 'Click', extLink);
          // }
          if (($(this).attr('target') !== undefined) && ($(this).attr('target').toLowerCase() !== '_blank')) {
            setTimeout((function () {
              location.href = href;
            }), 200);
            return false;
          }
        });
      } else if (href && href.match(/^mailto\:/i)) {
        $(this).click(function () {
          const mailLink = href.replace(/^mailto\:/i, '');
          // if (typeof window.ga !== 'undefined') {
          //   window.ga('send', 'event', 'Email', 'Click', mailLink);
          // }
        });
      } else if (href && href.match(download_path)) {
        $(this).click(function () {
          const filePath = href;
          // if (typeof window.ga !== 'undefined') {
          //   window.ga('send', 'event', 'Download', filePath);
          // }
          if (($(this).attr('target') !== undefined) && ($(this).attr('target').toLowerCase() !== '_blank')) {
            setTimeout((function () {
              location.href = baseHref + href;
            }), 200);
            return false;
          }
        });
      }
    });
  }
}