import BsCallbacks from 'scripts/custom/bs_callbacks';
import CoobiebotCallbacks from 'scripts/custom/cookiebot_callbacks';
import Lozad from 'lozad';
import Sticky from 'scripts/custom/sticky';
import Navigation from 'scripts/custom/navigation';
import Article from 'scripts/custom/article';
import Grid from 'scripts/custom/grid';
import Header from 'scripts/custom/header';
import AnchorsFixedHeader from 'scripts/custom/anchors_fixed_header';
import CreateNavigationScroll from 'scripts/custom/subnavigation_scroll';
import Cycle from 'scripts/custom/cycle';
import membershipApplicationForm from 'scripts/form_handler/membership_application_form'
import ExternalGaDownloadTracking from 'scripts/custom/external_ga_download_tracking'
import brochureOrderTracking from 'scripts/form_handler/brochure_order_tracking'
import sustaining_membership_application_form from '../form_handler/sustaining_membership_application_form';
import RegisterCookiebotFunctions from 'scripts/custom/cookiebot_functions';

export default () => {
  RegisterCookiebotFunctions();
  brochureOrderTracking();
  ExternalGaDownloadTracking();
  BsCallbacks();
  CoobiebotCallbacks();
  new Sticky('.static_subnav');
  Lozad('.lozad', {
    loaded: function (el) {
      el.classList.add('fade-in')
    }
  }).observe();
  Grid();
  Navigation();
  Header();
  Article();
  Cycle($('.cycle'));
  //has jquery
  membershipApplicationForm();
  CreateNavigationScroll();
  AnchorsFixedHeader('.anchor_link');
  sustaining_membership_application_form();
  // JqueryDatepicker();
  // setTimeout(CreateNavigationScroll, 200);
}